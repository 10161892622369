var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    class: "fill-current text-".concat(_vm.color),
    attrs: {
      "height": "".concat(_vm.height, "px"),
      "width": "".concat(_vm.width, "px"),
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M8 1.5C4.41594 1.5 1.5 4.41594 1.5 8C1.5 11.5841 4.41594 14.5 8 14.5C11.5841 14.5 14.5 11.5841 14.5 8C14.5 4.41594 11.5841 1.5 8 1.5ZM6.43062 5.15063C6.82656 4.73094 7.38375 4.5 8 4.5C8.61625 4.5 9.16844 4.7325 9.56594 5.15438C9.96875 5.58187 10.1647 6.15625 10.1184 6.77375C10.0259 8 9.07594 9 8 9C6.92406 9 5.97219 8 5.88156 6.77344C5.83563 6.15094 6.03125 5.57469 6.43062 5.15063ZM8 13.5C7.26577 13.5005 6.53892 13.3535 5.86255 13.0678C5.18618 12.7822 4.57406 12.3636 4.0625 11.8369C4.35549 11.4191 4.7288 11.0638 5.16062 10.7919C5.95719 10.2812 6.96531 10 8 10C9.03469 10 10.0428 10.2812 10.8384 10.7919C11.2706 11.0637 11.6443 11.4189 11.9375 11.8369C11.426 12.3636 10.8139 12.7823 10.1375 13.0679C9.46111 13.3536 8.73424 13.5006 8 13.5Z",
      "fill": "white"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }